import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import { Helmet } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import Routes from "./routes";

const App = () => {
    return <>
        <BrowserRouter>
            <Helmet
                titleTemplate = "%s | Payment Backend"
                defaultTitle = "Payment Backend"
            >
                <meta name="description" content="Payment Backend" />
                <meta http-equiv="Cache-Control" content="no-cache, no-store, must-revalidate" />
                <meta http-equiv="Pragma" content="no-cache" />
                <meta http-equiv="Expires" content="0" />
            </Helmet>
            <Routes/>
        </BrowserRouter>
    </>
}

export default App;