import Login from "app/components/Login";

const Home = () => {
    const submit = async () => {
        await new Promise(r => setTimeout(r, 2300));
        return "Wrong Username or Password"
    }

    return <Login submit = {submit}/>
}

export default Home;