import { TableCell, TableRow } from "@mui/material";

const ICashColumns = ({isAdmin}: any) => {
    return (
        <TableRow>
            <TableCell>virtualAccountNo</TableCell>
            <TableCell>transactionReferenceNo</TableCell>
            <TableCell>transactionStatus</TableCell>
            <TableCell>transactionDate</TableCell>
            <TableCell>currencyCode</TableCell>
            <TableCell>amount</TableCell>
            <TableCell>firstName</TableCell>
            <TableCell>lastName</TableCell>
        </TableRow>
    )
}

export default ICashColumns;