import { TableCell, TableRow } from "@mui/material";

const QPayColumns = ({isAdmin}: any) => {
    return isAdmin?
        <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Terminal ID</TableCell>
            <TableCell>Transaction ID</TableCell>
            <TableCell>Transaction Date</TableCell>
            <TableCell>Transaction Amount</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Reference Number</TableCell>
            <TableCell>Status</TableCell>
        </TableRow>:
        <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Transaction ID</TableCell>
            <TableCell>Transaction Date</TableCell>
            <TableCell>Transaction Amount</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Reference Number</TableCell>
            <TableCell>Status</TableCell>
        </TableRow>
}

export default QPayColumns;