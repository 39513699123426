import { Grid } from "@mui/material";
import TransactionsTable from "../TransactionsTable";

const BankTransactionsTable = ({partner, type, api, bank, source, intent, title, showBalance, showTotal}: any) => {
    return <>
        {partner?.profile?.banks?.[bank.replaceAll('-', '_')] && <Grid item xs={12}>
            <TransactionsTable
                bank                 = {bank}
                intent               = {intent}
                source               = {source}
                title                = {title}
                type                 = {type || ''}
                partner              = {partner}
                showBalance          = {showBalance}
                showTotal            = {showTotal}
                fetchBalance         = {api?.fetchBalance}
                fetchTotal           = {api?.fetchTotal}
                fetchTransactions    = {api?.fetchTransactions}
                downloadTransactions = {api?.downloadTransactions}
            />
        </Grid>}
    </>
}

export default BankTransactionsTable;