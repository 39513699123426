import { TableCell, TableRow } from "@mui/material";

const AllBankColumns = ({isAdmin}: any) => {
    return isAdmin?
        <TableRow>
            <TableCell>client</TableCell>
            {/* <TableCell>terminalID</TableCell> */}
            <TableCell>terminal_id</TableCell>
            <TableCell>terminal_name</TableCell>
            <TableCell>tranID</TableCell>
            <TableCell>bankReference</TableCell>
            <TableCell>payment_channel</TableCell>
            <TableCell>tranDate</TableCell>
            <TableCell>tranAmount</TableCell>
            <TableCell>runningBalance</TableCell>
            <TableCell>tranType</TableCell>
            <TableCell>reference</TableCell>
            <TableCell>senderRefid</TableCell>
            <TableCell>tranDescription</TableCell>
            <TableCell>tranCode</TableCell>
            <TableCell>ibftStatus</TableCell>
        </TableRow>:
        <TableRow>
            <TableCell>Transaction ID</TableCell>
            {/* <TableCell>Terminal ID</TableCell> */}
            <TableCell>Bank Reference</TableCell>
            <TableCell>Transaction Date</TableCell>
            <TableCell>Transaction Amount</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Reference</TableCell>
            <TableCell>Transaction Description</TableCell>
            <TableCell>Transaction Code</TableCell>
            <TableCell>Status</TableCell>
        </TableRow>
}

export default AllBankColumns;