import { TableCell, TableRow } from "@mui/material";

const ICashRow = ({item}: any) => {
    return (
        <TableRow hover>
            <TableCell>{item.virtualAccountNo || '-'}</TableCell>
            <TableCell>{item.transactionReferenceNo || '-'}</TableCell>
            <TableCell>{item.transactionStatus || '-'}</TableCell>
            <TableCell>{item.transactionDate || '-'}</TableCell>
            <TableCell>{item.currencyCode || '-'}</TableCell>
            <TableCell>{item.amount}</TableCell>
            <TableCell>{item.firstName || '-'}</TableCell>
            <TableCell>{item.lastName || '-'}</TableCell>
        </TableRow>
    )
}

export default ICashRow;