import { TableCell, TableRow } from "@mui/material";

const QPayRow = ({item, isAdmin, amountFormat, computeAmount}: any) => {
    return (
        isAdmin?
            <TableRow hover>
                <TableCell>{item.tranID}</TableCell>
                <TableCell>{item.terminal?.display_name || '-'}</TableCell>
                <TableCell>
                    {item.client_details?.transaction_id || '-'}
                </TableCell>
                <TableCell>{item.tranDate}</TableCell>
                <TableCell>{amountFormat(item.tranAmount)}</TableCell>
                <TableCell>{amountFormat(+item.net || computeAmount(item.tranAmount, 2))}</TableCell>
                <TableCell>
                    {item.client_details?.reference_number || '-'}
                </TableCell>
                <TableCell>{item.ibftStatus}</TableCell>
            </TableRow>:
            <TableRow hover>
                <TableCell>{item.tranID}</TableCell>
                <TableCell>
                    {item.client_details?.transaction_id || '-'}
                </TableCell>
                <TableCell>{item.tranDate}</TableCell>
                <TableCell>{amountFormat(item.tranAmount)}</TableCell>
                <TableCell>{amountFormat(+item.net || computeAmount(item.tranAmount, 2))}</TableCell>
                <TableCell>
                    {item.client_details?.reference_number || '-'}
                </TableCell>
                <TableCell>{item.ibftStatus}</TableCell>
            </TableRow>
    )
}

export default QPayRow;