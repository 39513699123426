import { useEffect, useState } from "react";

interface DashboardControllerProps {
    partner?: any
}

const PageController = ({partner}: DashboardControllerProps) => {
    // -- States --

    const [stack, setStack] = useState(['Dashboard']);
    const [page, setPage] = useState('login');
    const [account, setAccount] = useState('');

    // -- End Of States --
    // -- Functions --

    const redirectToDashboard = () => partner && setPage('dashboard');
    const redirectToResetPassword = () => setPage('resetPassword');

    const goToAccount = (account: string) => {
        setPage('accountsTable');
        setAccount(account);
        setStack([...stack, account]);
    }

    const navigateTo = () => {
        switch (stack.at(-1)) {
            case 'Dashboard': redirectToDashboard(); break;
            default:
                setPage('accountsTable');
                setAccount(stack.at(-1) || '');
        }
    }

    // -- End Of Functions --
    // -- useEffects --

    useEffect(redirectToDashboard, [partner]);
    useEffect(navigateTo, [stack]);

    // -- End Of useEffects --

    return {
        account,
        stack, setStack,
        page, setPage,
        goToAccount,
        redirectToDashboard,
        redirectToResetPassword,
    }
}

export default PageController;