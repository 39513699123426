import { Button, Card, CircularProgress, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { ACCOUNTS_TOTAL } from "app/urls";
import moment from "moment";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

interface Props {
    partner: any
    goToAccount: (account: string) => any
}

const AccountsTableController = ({partner}: Props) => {

    const {access_token} = partner;
    const {id, type} = useParams();
    const [accounts, setAccounts] = useState<any>({});
    const [loading, setLoading]   = useState<any>({});
    const [gross, setTotalGross]  = useState(0);
    const [net, setTotalNet]      = useState(0);
    const [start, setStart]       = useState(moment().format('YYYY-MM-DD'));
    const [end, setEnd]           = useState(moment().format('YYYY-MM-DD'));

    const amountFormat = (amount: number) => (+(amount || 0)).toLocaleString(undefined, {'minimumFractionDigits':2,'maximumFractionDigits':2})

    const getAccounts = () => {
        setLoading(true);
        let query = Object
            .entries({
                start, end,
                tranType: 'C',
                tranDescription: 'P2M'
            })
            .map(
                ([key, value]) => `${key}=${value}`
            )
            .join('&')
        fetch(ACCOUNTS_TOTAL({id, query}), {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${access_token}`
            },
        })
        .then(res => res.json())
        .then(res =>  setAccounts(res || {}))
        .finally(() => setLoading(false));
    }

    const computeTotal = () => {
        let gross = 0, net = 0;
        for (const acc in accounts) {
            gross += +accounts[acc].gross;
            net   += +accounts[acc].net;
        }
        setTotalGross(gross)
        setTotalNet(net)
    }

    useEffect(getAccounts, []);
    useEffect(computeTotal, [accounts]);

    return {
        accounts,
        gross, net,
        start, setStart,
        end, setEnd,
        loading, setLoading,
        apply: getAccounts,
        amountFormat,
    }
}

const AccountsTable = ({partner, goToAccount}: Props) => {
    const {
        accounts,
        loading,
        gross, net,
        start, setStart,
        end, setEnd,
        apply,
        amountFormat,
    }: any = AccountsTableController({partner, goToAccount});

    return <>
        <Grid item xs={true}>
            <Card style={styles.tableCard}>
                <Grid container alignItems = "center" alignContent="space-between" direction  = "row">
                    <Grid item xs={3}>
                        <Typography variant="h5" fontWeight="bold" sx={styles.tableTitle}>Total Gross: ₱ {amountFormat(gross)}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="h5" fontWeight="bold" sx={styles.tableTitle}>Total Net: ₱ {amountFormat(net)}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container
                            spacing        = {2}
                            alignItems     = "center"
                            justifyContent = "flex-end"
                        >
                            <Grid item>
                                <TextField
                                    id              = "date"
                                    label           = "Start Date"
                                    type            = "date"
                                    value           = {start}
                                    inputProps      = {{max: end}}
                                    InputLabelProps = {{shrink: true}}
                                    onChange        = {(e: any) => setStart(e.target.value)}
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    id              = "date"
                                    label           = "End Date"
                                    type            = "date"
                                    value           = {end}
                                    InputLabelProps = {{shrink: true}}
                                    onChange        = {(e: any) => setEnd(e.target.value)}
                                />
                            </Grid>
                            <Grid item>
                                <Button
                                    variant  = "contained"
                                    color    = "primary"
                                    size     = "large"
                                    onClick  = {apply}
                                    disabled = {!!loading}
                                >
                                    {loading? <CircularProgress size="20px" />: 'APPLY'}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <TableContainer component={Paper} sx={styles.tableContainer}>
                    <Table size="small" stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>Account</TableCell>
                                <TableCell>Gross</TableCell>
                                <TableCell>Net</TableCell>
                                <TableCell>OPC Balance</TableCell>
                                <TableCell>Total Cash-Outs</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(loading? []: Object.keys(accounts)).map((key: any) =>
                                <TableRow hover>
                                    <TableCell onClick={() => goToAccount(key)} sx={{":hover": {cursor: 'pointer'}}}>
                                        <Typography color="primary">{key}</Typography>
                                    </TableCell>
                                    <TableCell>₱ {amountFormat(+accounts[key].gross)}</TableCell>
                                    <TableCell>₱ {amountFormat(+accounts[key].net)}</TableCell>
                                    <TableCell>₱ {amountFormat(+accounts[key].balance)}</TableCell>
                                    <TableCell>{(+accounts[key].cash_outs || 0).toLocaleString(undefined, {maximumFractionDigits: 0})}</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Card>
        </Grid>
    </>
}

const styles = {
    tableCard: {
        minHeight: '100px',
        padding: '1rem'
    },
    tableContainer: {
        margin         : '1rem 0',
        height         : '100%',
        maxHeight      : 440,
        backgroundColor: "#fff"
    },
    tableTitle: {
        margin: 0
    },
    tableSubtitle: {
        margin    : 0,
        fontWeight: 'normal'
    },
    searchField: {
        marginRight: '1rem',
        marginLeft: '1rem'
    }
}

export default AccountsTable;