import { Button, Card, CircularProgress, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, TableSortLabel, TextField, Typography } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";

interface Props {
    bank_code: string
    bank_title: string
    partner: any
    fetchTerminals: (params: any, access_token: string) => Promise<any>
    removeTerminalId: (params: any, access_token: string) => Promise<any>
}

const TerminalTableController = ({bank_code, partner, fetchTerminals, removeTerminalId: removeTerminal}: Props) => {

    const {access_token}            = partner;
    const {id, type}                = useParams();
    const [terminals, setTerminals] = useState<any[]>([]);
    const [loading, setLoading]     = useState<any>({});
    const [total, setTotal]         = useState(0);
    const [start, setStart]         = useState(moment().format('YYYY-MM-DD'));
    const [end, setEnd]             = useState(moment().format('YYYY-MM-DD'));
    const [sortBy, setSortBy]       = useState('amount');
    const [sortOrder, setSortOrder] = useState('desc');

    const amountFormat = (amount: number) => (+(amount || 0)).toLocaleString(undefined, {'minimumFractionDigits':2,'maximumFractionDigits':2})

    const processTerminals = (terminals: any[]) => {
        return terminals?.map(
            (terminal) => ({
                terminal_id     : terminal.terminal_id,
                display_name    : terminal.display_name,
                amount          : Object.values(terminal.daily_total || {}).reduce(((sum, current) => (sum as any) + current), 0),
                last_transaction: terminal.last_transaction? `${terminal.last_transaction.tranDate} (tranID: ${terminal.last_transaction.tranID})`: '-',
            })
        ) || []
    }

    const getTerminals = () => {
        setLoading(true);
        let query = Object
            .entries({
                start, end,
                tranType: 'C',
                tranDescription: 'P2M'
            })
            .map(
                ([key, value]) => `${key}=${value}`
            )
            .join('&')
        fetchTerminals({id, bank: bank_code, query}, access_token)
        .then(res => res.status < 400? res.json(): [])
        .then(res =>  setTerminals(processTerminals(res || [])))
        .finally(() => setLoading(false));
    }

    const computeTotal = () => {
        setTotal(
            terminals?.reduce(
                (total: number, terminal: any) => {
                    return total + +(terminal.amount || 0)
                }, 0
            ) || 0
        )
    }

    const sort = (name: string) => {
        if (name == sortBy) {
            setSortOrder(sortOrder == 'desc'? 'asc': 'desc')
        } else {
            setSortBy(name)
            setSortOrder('desc')
        }
    }

    const sortMapping = (item: any) => ({
        terminal_id: item.display_name,
        name       : item.display_name,
        amount     : item.amount
    }[sortBy])

    const compareDesc = (a: any, b: any) => {
        let aValue = sortMapping(a),
            bValue = sortMapping(b);

        return (
            bValue < aValue? -1:
            bValue > aValue?  1: 0
        )
    }

    const sortTerminals = () => {
        setTerminals(
            terminals.sort((a, b) => (
                sortOrder === 'desc'?
                compareDesc(a, b):
                -compareDesc(a, b)
            ))
        )
    }
    
    const removeTerminalId = (terminal: any) => {
        Swal.fire({
            title: 'Remove Terminal ID',
            text: `Are you sure you want to remove "${terminal.display_name}"?`,
            icon: 'warning',
            confirmButtonText: 'Confirm',
            // cancelButtonText: 'Cancel',
            showCancelButton: true
        }).then((value) => {
            if (value.isConfirmed) {
                setLoading(true);
                removeTerminal({id, bank: bank_code, terminal_id: terminal.terminal_id}, access_token)
                .then(res => {
                    if (res.status < 400) {
                        setTerminals(terminals.filter(t => t.terminal_id != terminal.terminal_id));
                        Swal.fire({
                            title: 'Removed Successfully',
                            icon: 'success',
                        })
                    } else {
                        Swal.fire({
                            title: 'Failed to remove',
                            icon: 'error',
                        })
                    }
                })
                .finally(() => setLoading(false));
            }
        })
    }

    useEffect(getTerminals, []);
    useEffect(computeTotal, [terminals]);
    useEffect(sortTerminals, [sortBy, sortOrder, terminals]);

    return {
        terminals,
        total,
        start, setStart,
        end, setEnd,
        loading, setLoading,
        apply: getTerminals,
        sort, sortBy, sortOrder,
        amountFormat,
        removeTerminalId
    }
}

const TerminalTable = (props: Props) => {
    const {
        terminals,
        totalAmount,
        loading,
        start, setStart,
        end, setEnd,
        apply,
        sort, sortBy, sortOrder,
        amountFormat,
        removeTerminalId
    }: any = TerminalTableController(props);

    return <>
        <Grid item xs={true}>
            <Card style={styles.tableCard}>
                <Grid container alignItems="center" alignContent="space-between" direction="row">
                    <Grid item xs={4}>
                        <Typography variant="h5" fontWeight="bold" sx={styles.tableTitle}>{props.bank_title} Terminal IDs</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Grid container
                            spacing        = {2}
                            alignItems     = "center"
                            justifyContent = "flex-end"
                        >
                            <Grid item>
                                <TextField
                                    id              = "date"
                                    label           = "Start Date"
                                    type            = "date"
                                    value           = {start}
                                    inputProps      = {{max: end}}
                                    InputLabelProps = {{shrink: true}}
                                    onChange        = {(e: any) => setStart(e.target.value)}
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    id              = "date"
                                    label           = "End Date"
                                    type            = "date"
                                    value           = {end}
                                    InputLabelProps = {{shrink: true}}
                                    onChange        = {(e: any) => setEnd(e.target.value)}
                                />
                            </Grid>
                            <Grid item>
                                <Button
                                    variant  = "contained"
                                    color    = "primary"
                                    size     = "large"
                                    onClick  = {apply}
                                    disabled = {!!loading}
                                >
                                    {loading? <CircularProgress size="20px" />: 'APPLY'}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <TableContainer component={Paper} sx={styles.tableContainer}>
                    <Table size="small" stickyHeader>
                        <TableHead>
                            <TableRow>
                                {Object.entries({
                                    terminal_id: "Terminal ID",
                                    // name: "Name",
                                    amount: "Amount",
                                    last_transaction: "Last Transaction",
                                }).map(([key, name]) => (
                                    <TableCell sortDirection={sortBy === key? sortOrder : false}>
                                        <TableSortLabel
                                            active    = {sortBy === key}
                                            direction = {sortBy === key? sortOrder : 'asc'}
                                            onClick   = {() => sort(key)}
                                        >
                                            {name}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(loading? []: terminals).map((terminal: any) =>
                                <TableRow hover>
                                    {/* <TableCell>{terminal.terminal_id}</TableCell> */}
                                    <TableCell>{terminal.display_name}</TableCell>
                                    <TableCell>₱ {amountFormat(terminal.amount)}</TableCell>
                                    <TableCell>{terminal.last_transaction}</TableCell>
                                    <TableCell>
                                        <Button variant="text" onClick={() => removeTerminalId(terminal)}>Remove</Button>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                        <TableFooter sx={{position: 'sticky', bottom: 0, background: 'white'}}>
                            <TableRow>
                                <TableCell>
                                    <Typography fontWeight="bold" textAlign="right">TOTAL:</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography fontWeight="bold">₱ {amountFormat(totalAmount)}</Typography>
                                </TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Card>
        </Grid>
    </>
}

const styles = {
    tableCard: {
        minHeight: '100px',
        padding: '1rem'
    },
    tableContainer: {
        margin         : '1rem 0',
        height         : '100%',
        maxHeight      : 440,
        backgroundColor: "#fff"
    },
    tableTitle: {
        margin: 0
    },
    tableSubtitle: {
        margin    : 0,
        fontWeight: 'normal'
    },
    searchField: {
        marginRight: '1rem',
        marginLeft: '1rem'
    }
}

export default TerminalTable;