import { RequestResetForm } from "app/components/RequestReset";
import { CHECK_RESET_PASSWORD_TOKEN, RESET_PASSWORD } from "app/urls";
import { useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";

const ResetPasswordController = () => {
    // -- States --

    const {id, type} = useParams();
    const [params, _] = useSearchParams();

    // -- End Of State --
    // -- Functions --

    const resetPassword = async (form: RequestResetForm) => {
        let response = await fetch(RESET_PASSWORD({id, type}), {
            method : "POST",
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(form)
        });
        let body = await response.text().catch(() => "");
        try {
            body = JSON.parse(body);
        } catch {}
        if (!body) {
            return {success: true, message: ""}
        }
        return {success: false, message: body}
    }

    const checkResetPasswordToken = () => {
        const token = params.get('reset_password');
        if (token) {
            fetch(CHECK_RESET_PASSWORD_TOKEN({id, type}), {
                method : "POST",
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({token})
            }).then(
                response => {
                    if (response.status == 200) {
                        window.location.href = `/${type}/${id}/reset-password?token=${token}`
                    }
                }
            )
        }
    }

    // -- End Of Functions --
    // -- useEffects --

    useEffect(checkResetPasswordToken, [params])

    // -- End Of useEffects --

    return {
        resetPassword
    }
}

export default ResetPasswordController;