import { Grid } from "@mui/material";
import TerminalTable from "app/components/TerminalTable";
import BankTransactionsTable from "app/components/TransactionTables/BankTransactionsTable";

const PartnerDashboard = ({partner, type, api}: any) => {
    return <>
        <Grid container spacing={3}>
            {partner?.profile?.banks?.allbank && <>
                {partner?.profile?.p2m?.enabled &&
                    <BankTransactionsTable showTotal
                        bank    = "allbank"
                        source  = "p2m"
                        title   = "QRPH"
                        partner = {partner}
                        type    = {type || ''}
                        api     = {api}
                    />
                }
                {partner?.profile?.opc?.enabled &&
                    <BankTransactionsTable showTotal
                        bank    = "allbank"
                        source  = "opc"
                        title   = "AllBank - OPC"
                        partner = {partner}
                        type    = {type || ''}
                        api     = {api}
                    />
                }
            </>}
            {partner?.profile?.banks?.jpt && <>
                {partner?.profile?.jpt?.cash_in &&
                    <BankTransactionsTable showTotal
                        bank    = "jpt"
                        intent  = "cash_in"
                        title   = "Cash In"
                        partner = {partner}
                        type    = {type || ''}
                        api     = {api}
                    />
                }
                {partner?.profile?.jpt?.cash_out &&
                    <BankTransactionsTable showBalance
                        bank    = "jpt"
                        intent  = "cash_out"
                        title   = "Cash Out"
                        partner = {partner}
                        type    = {type || ''}
                        api     = {api}
                    />
                }
            </>}
            {partner?.profile?.banks?.credit_debit &&
                <BankTransactionsTable showTotal
                    bank    = "credit-debit"
                    title   = "Credit/Debit Cards"
                    partner = {partner}
                    type    = {type || ''}
                    api     = {api}
                />
            }
            {partner?.profile?.banks?.rbgi &&
                <BankTransactionsTable showTotal
                    bank    = "rbgi"
                    title   = "RBGI"
                    partner = {partner}
                    type    = {type || ''}
                    api     = {api}
                />
            }
            {partner?.profile?.banks?.icash &&
                <BankTransactionsTable showTotal
                    bank    = "icash"
                    title   = "ICash"
                    partner = {partner}
                    type    = {type || ''}
                    api     = {api}
                />
            }
            {partner?.profile?.banks?.gcash &&
                <BankTransactionsTable showTotal
                    bank    = "gcash"
                    title   = "GCash"
                    partner = {partner}
                    type    = {type || ''}
                    api     = {api}
                />
            }
            {partner?.profile?.terminal_ids?.allbank &&
                <Grid item xs={12} lg={6}>
                    <TerminalTable  
                        bank_code        = "allbank"
                        bank_title       = "AllBank"
                        partner          = {partner}
                        fetchTerminals   = {api?.fetchTerminals}
                        removeTerminalId = {api?.removeTerminalId}
                    />
                </Grid>
            }
            {partner?.profile?.terminal_ids?.rbgi &&
                <Grid item xs={12} lg={6}>
                    <TerminalTable
                        bank_code        = "rbgi"
                        bank_title       = "RBGI"
                        partner          = {partner}
                        fetchTerminals   = {api?.fetchTerminals}
                        removeTerminalId = {api?.removeTerminalId}
                    />
                </Grid>
            }
            {partner?.profile?.terminal_ids?.icash &&
                <Grid item xs={12} lg={6}>
                    <TerminalTable
                        bank_code        = "icash"
                        bank_title       = "ICash"
                        partner          = {partner}
                        fetchTerminals   = {api?.fetchTerminals}
                        removeTerminalId = {api?.removeTerminalId}
                    />
                </Grid>
            }
        </Grid>
    </>
}

export default PartnerDashboard;