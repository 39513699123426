
import { Box, Button, CircularProgress, Grid, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useParams } from "react-router-dom";

interface Props {
    submit?: (formData: RequestResetForm) => Promise<any>
}

export interface RequestResetForm {
    username: string
    email: string
}

const Controller = ({submit}: Props) => {
    // -- States --

    const [username, setUsername] = useState("");
    const [email, setEmail]       = useState("");
    const [error, setError]       = useState("");
    const [success, setSuccess]   = useState(false);
    const [loading, setLoading]   = useState(false);

    // -- End of States --
    // -- Functions --

    const onSubmit = async (e: any) => {
        e.preventDefault();
        setLoading(true);
        setError("");
        let message = "";
        if (!username) {
            message = "Username is required";
        } else if (!email) {
            message = "Email is required";
        }
        if (message) {
            return handleResult({message, success: false});
        }
        submit && handleResult(await submit({username, email}));
    }

    const handleResult = ({success, message}: any) => {
        setLoading(false);
        setError(success? "": message);
        setSuccess(success);
    }

    // -- End of Functions --

    return {
        username, setUsername,
        email, setEmail,
        error, setError,
        loading, setLoading,
        onSubmit, success
    }
}

const RequestReset = (prop: Props) => {
    const {id, type} = useParams();
    const {
        username, setUsername,
        email, setEmail,
        error, loading,
        onSubmit, success
    } = Controller(prop);

    return <form onSubmit={onSubmit}>
        <Grid container justifyContent="center" alignContent="center" alignItems="center" padding="2rem">
            <Grid item>
                <Typography variant="h4" sx={styles.title}>
                    PAYMENT BACKEND
                </Typography>
                {success ? <Box sx={styles.successContainer}>
                        <Typography color="green" sx={styles.message}>
                            An email has been successfully sent to you. Please check your email
                        </Typography>
                        <Button sx={styles.button} href={`/${type}/${id}`}>Login</Button>
                    </Box>: <>
                    {error && <Typography color="red" sx={styles.message}>
                        {error}
                    </Typography>}
                    <TextField fullWidth
                        placeholder = "Username"
                        sx          = {styles.formField}
                        value       = {username}
                        onChange    = {e => setUsername(e.target.value)}
                    />
                    <TextField fullWidth
                        placeholder = "Registered Email"
                        type        = "email"
                        sx          = {styles.formField}
                        value       = {email}
                        onChange    = {e => setEmail(e.target.value)}
                    />
                    <Button fullWidth
                        type     = "submit"
                        variant  = "contained"
                        sx       = {styles.button}
                        disabled = {!!loading}
                    >
                        {loading? <CircularProgress size={25}/>: "Reset Password"}
                    </Button>
                </>}
            </Grid>
        </Grid>
    </form>
}

const styles = {
    title: {
        textAlign: "center",
        textTransform: "uppercase",
        margin: "2rem 0"
    },
    successContainer: {
        textAlign: "center"
    },
    message: {
        marginTop: "2rem"
    },
    formField: {
        marginTop: "2rem"
    },
    button: {
        marginTop: "2rem"
    }
}

export default RequestReset;