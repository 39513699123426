import RequestReset from "app/components/RequestReset";
import { Navigate } from "react-router-dom";
import AccountTable from "../AccountTable";
import Login from "../Login";
import DashboardPage from "./Dashboard";
import LoginController from "./controllers/LoginController";
import PageController from "./controllers/PageController";
import ResetPasswordController from "./controllers/ResetPasswordController";

const Dashboard = () => {

    // -- Controllers --

    const {partner, login} = LoginController();
    const {resetPassword} = ResetPasswordController();
    const {
        page, account,
        stack, setStack,
        goToAccount,
        redirectToResetPassword
    } = PageController({partner});
    
    // -- End Of Controllers --

    switch (page) {
        case 'login'        : return <Login login={login} redirectToResetPassword={redirectToResetPassword}/>
        case 'resetPassword': return <RequestReset submit={resetPassword}/>
        case 'dashboard'    : return <DashboardPage partner={partner} goToAccount={goToAccount} stack={stack} setStack={setStack}/>
        case 'accountsTable': return <AccountTable partner={partner} account={account} stack={stack} setStack={setStack}/>
    }
    
    return <Navigate to="/"/>
}

export default Dashboard;