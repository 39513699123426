import { Alert, Button, Card, CircularProgress, FormControl, Grid, MenuItem, Paper, Select, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { ACCOUNTS_URL, ALL_TERMINALS } from "app/urls";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

interface Props {
    bank_title: string
    partner: any
}

const Controller = ({partner}: Props) => {

    const {access_token}            = partner;
    const {id}                      = useParams();
    const [accounts, setAccounts]   = useState<any[]>([]);
    const [terminals, setTerminals] = useState<any[]>([]);
    const [loading, setLoading]     = useState(true);

    const getAccounts = () => {
        id && fetch(ACCOUNTS_URL(id), {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${access_token}`
            },
        })
        .then(res => res.json())
        .then(res =>  setAccounts(res || {}));
    }

    const getTerminals = () => {
        setLoading(true)
        if (id && access_token) {
            let query = Object
                .entries({
                    tranType: 'C',
                    tranDescription: 'P2M'
                })
                .map(
                    ([key, value]) => `${key}=${value}`
                )
                .join('&')
            fetch(ALL_TERMINALS({id, query}), {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${access_token}`
                }
            })
            .then(response => response.json())
            .then(response => setTerminals(response))
            .finally(() => setLoading(false));
        } else {
            setLoading(false);
        }
    }

    useEffect(getAccounts, []);
    useEffect(getTerminals, [])

    return {
        loading,
        accounts,
        terminals
    }
}

const AccountForm = ({access_token, tid, name, username, accounts, onSaveCallback}: any) => {
    
    const {id} = useParams();
    const [saving, setSaving] = useState(false);
    const [saved, setSaved] = useState(false);
    const [selected, setSelected] = useState(username);

    const save = () => {
        setSaving(true);
        setSaved(false);
        fetch(`${ALL_TERMINALS({id, query: ''})}/${tid}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${access_token}`
            },
            body: JSON.stringify({username: selected || ''})
        })
        .then(res => {
            onSaveCallback()
            res.status < 300 && setSaved(true)
        })
        .finally(() => {
            setSaving(false)
        })
    }


    return <>
        <FormControl size="small" sx={{minWidth: 150}}>
            <Select variant="outlined" autoWidth displayEmpty value={selected} placeholder="Select Account" onChange={e => setSelected(e.target.value)} disabled={tid == 0}>
                <MenuItem>-</MenuItem>
                {accounts.map((account: any) => (
                    <MenuItem value={account.username}>{account.username}</MenuItem>
                ))}
            </Select>
        </FormControl>
        <Button
            disabled={tid == 0 || saving}
            variant="contained"
            onClick={tid > 0? save: undefined}
            sx={{margin: '0 0.5rem', borderRadius: '100px', textTransform: 'none'}}
        >
            {saving? <CircularProgress size={25}/>: 'Save'}
        </Button>
        <Snackbar
            autoHideDuration = {2000}
            open             = {saved}
            anchorOrigin     = {{vertical: 'top', horizontal: 'center'}}
            onClose          = {() => setSaved(false)}
        >
            <Alert
                onClose  = {() => setSaved(false)}
                severity = "success"
                variant  = "filled"
                sx       = {{ width: '100%' }}
            >
                Saved Terminal "{name}"
            </Alert>
        </Snackbar>
    </>
}

const AdminTerminalTable = (props: Props) => {

    const {
        loading,
        accounts,
        terminals,
    } = Controller(props);

    return <>
        <Grid item xs={true}>
            <Card style={styles.tableCard}>
                <Grid container alignItems="center" alignContent="space-between" direction="row">
                    <Grid item xs={6}>
                        <Typography variant="h5" fontWeight="bold" sx={styles.tableTitle}>{props.bank_title} - Terminal IDs</Typography>
                    </Grid>
                </Grid>
                <TableContainer component={Paper} sx={styles.tableContainer}>
                    <Table size="small" stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Account</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(loading? []: terminals).map((terminal: any, index: number) =>
                                <TableRow hover>
                                    <TableCell>{terminal.terminal_id}</TableCell>
                                    <TableCell>{terminal.display_name}</TableCell>
                                    <TableCell>
                                        <AccountForm
                                            access_token   = {props.partner.access_token}
                                            tid            = {terminal.terminal_id}
                                            name           = {terminal.display_name}
                                            accounts       = {accounts}
                                            onSaveCallback = {() => {}}
                                            username       = {
                                                terminal.client && terminal.account?
                                                `${terminal.client}-${terminal.account}`: undefined
                                            }
                                        />
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Card>
        </Grid>
    </>
}

const styles = {
    tableCard: {
        minHeight: '100px',
        padding: '1rem'
    },
    tableContainer: {
        margin         : '1rem 0',
        height         : '100%',
        maxHeight      : 440,
        backgroundColor: "#fff"
    },
    tableTitle: {
        margin: 0
    },
    tableSubtitle: {
        margin    : 0,
        fontWeight: 'normal'
    },
    searchField: {
        marginRight: '1rem',
        marginLeft: '1rem'
    }
}

export default AdminTerminalTable;