
import { Button, CircularProgress, Grid, TextField, Typography } from "@mui/material";
import { useState } from "react";

interface Props {
    submit?: (formData: LoginForm) => Promise<any>
}

export interface LoginForm {
    username: string
    password: string
}

const Controller = ({submit}: Props) => {
    // -- States --

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError]       = useState("");
    const [loading, setLoading]   = useState(false);

    // -- End of States --
    // -- Functions --

    const onSubmit = async (e: any) => {
        e.preventDefault();
        setLoading(true);
        setError("");
        if (!username) {
            return handleResult("Username is required");
        }
        if (!password) {
            return handleResult("Password is required");
        }
        const result = submit && await submit({username, password}) || '';
        handleResult(result)
    }

    const handleResult = (message: string) => {
        setLoading(false);
        setError(message)
    }

    // -- End of Functions --

    return {
        username, setUsername,
        password, setPassword,
        error, setError,
        loading, setLoading,
        onSubmit
    }
}

const Login = (prop: Props) => {
    const {
        username, setUsername,
        password, setPassword,
        error, loading,
        onSubmit
    } = Controller(prop);

    return <form onSubmit={onSubmit}>
        <Grid container justifyContent="center" alignContent="center" alignItems="center" padding="2rem">
            <Grid item>
                <Typography variant="h4" sx={styles.title}>
                    PAYMENT BACKEND
                </Typography>
                {error && <Typography color="red" sx={styles.message}>
                    {error}
                </Typography>}
                <TextField fullWidth
                    placeholder = "Username"
                    sx          = {styles.formField}
                    value       = {username}
                    onChange    = {e => setUsername(e.target.value)}
                />
                <TextField fullWidth
                    placeholder = "Password"
                    type        = "password"
                    sx          = {styles.formField}
                    value       = {password}
                    onChange    = {e => setPassword(e.target.value)}
                />
                <Button fullWidth
                    type     = "submit"
                    variant  = "contained"
                    sx       = {styles.button}
                    disabled = {!!loading}
                >
                    {loading? <CircularProgress size={25}/>: "Login"}
                </Button>
            </Grid>
        </Grid>
    </form>
}

const styles = {
    title: {
        textAlign: "center",
        textTransform: "uppercase",
        margin: "2rem 0"
    },
    message: {
        textAlign: "center",
        marginTop: "2rem"
    },
    formField: {
        marginTop: "2rem"
    },
    button: {
        marginTop: "2rem"
    }
}

export default Login;