import { BALANCE, SETTLEMENTS_URL } from "app/urls";
import { useParams } from "react-router-dom";
import useAuth from "./useAuth";

const useApi = () => {
    const id             = 'settlements';
    const base_url       = SETTLEMENTS_URL();
    const {access_token} = useAuth();
    const {type}         = useParams();
    const headers        = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${access_token}`
    }

    const fetchAccounts = (date: string) => (
        fetch(`${base_url}/accounts?date=${date}`, {headers})
        .then(res => res.json())
    )

    const fetchBalance = (source: string) => (
        fetch(
            BALANCE({id, type, source, bank: 'allbank'}), {headers}
        ).then(res => res.json())
    )

    const changeSettlement = (
        payload: {
            date        : string
            source      : string
            total       : number
            account_name: string
        }
    ) => {
        let url    = `${base_url}/change`,
            method = 'PUT',
            body   = JSON.stringify(payload);
        return fetch(url, {method, headers, body})
    }

    const approveSettlement = (
        payload: {
            date           : string
            source         : string
            approver_number: number
            account_name   : string
        }
    ) => {
        let url     = `${base_url}/approve`,
            method  = 'POST',
            headers = {
                "Content-Type" : 'application/json',
                "Authorization": `Bearer ${access_token}`
            },
            body = JSON.stringify(payload);
        return fetch(url, {method, headers, body})
    }

    return {
        fetchAccounts,
        fetchBalance,
        changeSettlement,
        approveSettlement
    }
}

export default useApi;