
import { Button, CircularProgress, Grid, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useParams } from "react-router-dom";

interface Props {
    submit?: (formData: ResetPasswordForm) => Promise<any>
}

export interface ResetPasswordForm {
    password: string
}

const Controller = ({submit}: Props) => {
    // -- States --

    const [newPassword, setNewPassword]         = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError]                     = useState("");
    const [success, setSuccess]                 = useState(false);
    const [loading, setLoading]                 = useState(false);

    // -- End of States --
    // -- Functions --

    const onSubmit = async (e: any) => {
        e.preventDefault();
        setLoading(true);
        setError("");
        let message = "";
        if (!newPassword) {
            message = "Please enter new password";
        } else if (newPassword != confirmPassword) {
            message = "Password does not match";
        } else if (newPassword.length < 9 || newPassword.length > 64) {
            message = "Password length must be between 9 and 64";
        } else if (!/[A-Z]/.test(newPassword)) {
            message = "Password must contain an uppercase character";
        } else if (!/[a-z]/.test(newPassword)) {
            message = "Password must contain a lowercase character";
        } else if (!/[0-9]/.test(newPassword)) {
            message = "Password must contain a number";
        } else if (!/[0-9]/.test(newPassword)) {
            message = "Password must contain a number";
        } else if (!/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(newPassword)) {
            message = "Password must contain a special character";
        }
        if (message) {
            return handleResult({message, success: false})
        }
        if (submit) {
            message = await submit({password: newPassword});
            return handleResult({message, success: !!message})
        }
    }

    const handleResult = ({success, message}: any) => {
        setLoading(false);
        setError(success? "": message);
        setSuccess(success);
    }

    // -- End of Functions --

    return {
        newPassword, setNewPassword,
        confirmPassword, setConfirmPassword,
        error, setError,
        loading, setLoading,
        onSubmit, success
    }
}

const RequestReset = (prop: Props) => {
    const {id, type} = useParams();
    const {
        newPassword, setNewPassword,
        confirmPassword, setConfirmPassword,
        error, loading,
        onSubmit, success
    } = Controller(prop);

    return <form onSubmit={onSubmit}>
        <Grid container justifyContent="center" alignContent="center" alignItems="center" padding="2rem">
            <Grid item>
                <Typography variant="h4" sx={styles.title}>
                    PAYMENT BACKEND
                </Typography>
                {error && <Typography color="red" sx={styles.message}>
                    {error}
                </Typography>}
                {
                    success?
                    <div style={{textAlign: 'center'}}>
                        <Typography>Password Reset Succesfully</Typography>
                        <Button style={styles.button} href={`/${type}/${id}`}>Login</Button>
                    </div>: <>
                        <TextField fullWidth
                            placeholder = "New Password"
                            type        = "password"
                            sx          = {styles.formField}
                            value       = {newPassword}
                            onChange    = {e => setNewPassword(e.target.value)}
                        />
                        <TextField fullWidth
                            placeholder = "Confirm Password"
                            type        = "password"
                            sx          = {styles.formField}
                            value       = {confirmPassword}
                            onChange    = {e => setConfirmPassword(e.target.value)}
                        />
                        <Button fullWidth
                            type     = "submit"
                            variant  = "contained"
                            sx       = {styles.button}
                            disabled = {!!loading}
                        >
                            {loading? <CircularProgress size={25}/>: "Reset Password"}
                        </Button>
                    </>
                }
            </Grid>
        </Grid>
    </form>
}

const styles = {
    title: {
        textAlign: "center",
        textTransform: "uppercase",
        margin: "2rem 0"
    },
    message: {
        textAlign: "center",
        marginTop: "2rem"
    },
    formField: {
        marginTop: "2rem"
    },
    button: {
        marginTop: "2rem"
    }
}

export default RequestReset;