import { Button, Card, CircularProgress, Grid, Table as MuiTable, Paper, TableBody, TableContainer, TableHead, TablePagination, TextField, Typography } from "@mui/material";
import moment from "moment";
import { memo, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import AllBankColumns from "./TransactionTables/TableColumns/AllBankColumns";
import CreditDebitColumns from "./TransactionTables/TableColumns/CreditDebit";
import GCashColumns from "./TransactionTables/TableColumns/GCashColumns";
import ICashColumns from "./TransactionTables/TableColumns/ICashColumns";
import JPTColumns from "./TransactionTables/TableColumns/JPTColumn";
import QPayColumns from "./TransactionTables/TableColumns/QPayColumns";
import RBGIColumns from "./TransactionTables/TableColumns/RBGIColumns";
import AllBankRow from "./TransactionTables/TableRows/AllBankRow";
import CreditDebitRow from "./TransactionTables/TableRows/CreditDebitRow";
import GCashRow from "./TransactionTables/TableRows/GCashRow";
import ICashRow from "./TransactionTables/TableRows/ICash";
import JPTRow from "./TransactionTables/TableRows/JPTRow";
import QPayRow from "./TransactionTables/TableRows/QPayRow";
import RBGIRow from "./TransactionTables/TableRows/RBGIRow";

interface Props {
    type        : string
    title?      : string
    bank        : string
    source     ?: string
    intent     ?: string
    showBalance?: boolean
    showTotal  ?: boolean
    partner     : any
    fetchBalance        ?: (params: any, access_token: string) => Promise<any>
    fetchTotal          ?: (params: any, access_token: string) => Promise<any>
    fetchTransactions   ?: (params: any, access_token: string) => Promise<any>
    downloadTransactions?: (params: any, access_token: string) => Promise<any>
}

const TransactionsTableController = ({type, bank, partner, source, intent, fetchBalance, fetchTotal, fetchTransactions, downloadTransactions}: Props) => {
    const limit = 10000;
    const rowsPerPage = 1000;
    const {access_token} = partner;
    const {id, type: user_type} = useParams();

    const [balance, setBalance]           = useState(0);
    const [start, setStart]               = useState(moment().format('YYYY-MM-DD'));
    const [end, setEnd]                   = useState(moment().format('YYYY-MM-DD'));
    const [search, setSearch]             = useState('');
    const [page, setPage]                 = useState(0);
    const [transactions, setTransactions] = useState<any[]>([]);
    const [loading, setLoading]           = useState(true);
    const [total, setTotal]               = useState('');
    const [loadingTitle, setLoadingTitle] = useState(true);
    const [downloading, setDownloading]   = useState(false);
    const [last_key, setLastKey]          = useState<string>('');

    const getBalance = () => {
        fetchBalance && fetchBalance({id, bank, source, type: user_type}, access_token)
            .then(res => res.json())
            .then(res => setBalance(+(res.CurrentBalance || 0)))
            .finally(() => setLoadingTitle(false))
    }
    const getQuery = (data: any, include_last_key = true) => {
        include_last_key && last_key && (data['last_key'] = JSON.stringify(last_key));
        if ('partner' == type?.toLowerCase()) {
            data = {
                ...data,
                tranType: 'C',
                tranDescription: 'P2M',
            }
        }
        if (!data['intent']) {
            delete data['intent'];
        }
        return Object
            .entries(data)
            .map(
                ([key, value]) => `${key}=${value}`
            )
            .join('&')
    }
    const getTotal = async () => {
        let query = getQuery({
            start, end, intent,
            tranType: 'C',
            tranDescription: 'P2M',
        });
        source?.toLowerCase();
        if (fetchTotal) {
            let response = await fetchTotal({id, type, bank, source, query}, access_token).then(res => {
                if (res.status == 401) {
                    sessionExpired()
                }
                return res.text()
            }).catch(() => sessionExpired())
            setTotal(amountFormat(+response));
        }
        setLoadingTitle(false)
    }
    const getTransactions = async () => {
        let query = getQuery({start, end, limit, intent});
        source?.toLowerCase();
        fetchTransactions && await fetchTransactions({id, type, bank, source, query}, access_token).then(res => {
            if (res.status == 401) {
                sessionExpired()
            }
            return res
        })
        .then(res => res.json())
        .then(response => {
            if (last_key) {
                response.transactions = [...transactions, ...response.transactions];
            }
            setLastKey(response.LastEvaluatedKey);
            setTransactions(response.transactions);
        })
        .catch(() => sessionExpired())
        setLoading(false);
    }

    const sessionExpired = () => Swal.fire({
        title: 'Session Expired',
        text: 'Please login again',
        icon: 'warning',
        allowOutsideClick: false
    }).then((value) => {
        if (value.isConfirmed) {
            sessionStorage.clear()
            window.location.reload()
        }
    })

    const amountFormat = (amount: number) => (+(amount || 0)).toLocaleString(undefined, {'minimumFractionDigits':2,'maximumFractionDigits':2})
    const computeAmount = (amount: number, fee_percentage: number) => amount - amount * fee_percentage/100
    const filter = (i: any) => JSON.stringify(i).toLowerCase().includes(search)
    const resetPage = () => {page < 0 && setPage(0)}

    const apply = () => {
        setLoading(true)
        setLoadingTitle(true)
        setPage(0)
        switch (bank) {
            case 'gcash'       : getTotal(); break;
            case 'credit-debit': getTotal(); break;
            default:
                switch (type?.toLowerCase()) {
                    case 'admin':
                        switch (intent) {
                            case 'cash_out': getTotal(); break;
                            default: getBalance(); break;
                        }
                        break
                    case 'partner':
                        switch (intent) {
                            case 'cash_out': getBalance(); break;
                            default: getTotal(); break;
                        }
                        break
                }
        }
        getTransactions()
    }

    const onChangePage = (_: any, page: number) => {
        if (page*rowsPerPage > transactions.length) {
            if (last_key) {
                getTransactions()
            } else {
                return setPage(page-1)
            }
        }
        setPage(page)
    }

    const transactionsLoaded = () => {
        if (page*rowsPerPage > transactions?.length) {
            setPage(page - 1)
        }
    }

    const download = async (file_type: 'xlsx' | 'csv') => {
        setDownloading(true);
        let query = getQuery({start, end, intent}, false);
        downloadTransactions && await downloadTransactions({id, type, bank, source, query, file_type}, access_token).then(async res => {
            if (res.status == 401) {
                Swal.fire({
                    title: 'Session Expired',
                    text: 'Please login again',
                    icon: 'warning',
                    allowOutsideClick: false
                }).then((value) => {
                    if (value.isConfirmed) {
                        sessionStorage.clear()
                        window.location.reload()
                    }
                })
            }
            return {
                filename: decodeURI(res.headers.get('Content-Disposition')?.replace("attachment; filename*=utf-8''", '') || ''),
                blob    : await res.blob()
            }
        })
        .then(({filename, blob}) => {
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = filename;
            link.target = "_blank";
            link.click();
            link.remove();
        })
        .catch(() => sessionExpired())
        setDownloading(false);
    }

    useEffect(apply, [])
    useEffect(resetPage, [search])
    useEffect(resetPage, [page]);
    useEffect(transactionsLoaded, [transactions])

    return {
        isAdmin: 'admin' == user_type?.toLowerCase(),
        transactions: transactions?.filter(filter)?.slice(
            page*rowsPerPage,
            page*rowsPerPage+rowsPerPage
        ) || [],
        balance: balance.toLocaleString(undefined, {'minimumFractionDigits':2,'maximumFractionDigits':2}),
        columns: ('admin' == type?.toLowerCase()?
            [
                'tranID',
                'bankReference',
                'tranDate',
                'tranAmount',
                'runningBalance',
                'reference',
                'senderRefid',
                'tranDescription',
                'tranCode',
                'ibftStatus',
            ]:
            [
                'Transaction ID',
                'Bank Reference',
                'Transaction Date',
                'Transaction Amount',
                'Amount',
                'Reference',
                'Transaction Description',
                'Transaction Code',
                'Status',
            ]
        ),
        count: transactions?.filter(filter)?.length || 0,
        total,
        loading,
        loadingTitle,
        rowsPerPage,
        search, setSearch,
        start, setStart,
        end, setEnd,
        page, onChangePage,
        apply,
        amountFormat,
        computeAmount,
        download,
        downloading
    }
}

const TransactionsTable = memo(({title, bank, intent, showBalance, showTotal, ...props}: Props) => {
    const {
        isAdmin,
        downloading,
        transactions,
        count, total,
        loading,
        loadingTitle,
        balance,
        rowsPerPage,
        search, setSearch,
        start, setStart,
        end, setEnd,
        page, onChangePage,
        apply,
        amountFormat,
        computeAmount,
        download
    } = TransactionsTableController({bank, intent, ...props});

    return <>
        <Grid item xs={true}>
            <Card style={styles.tableCard}>
                <Grid container alignItems = "center" alignContent="space-between" direction  = "row">
                    <Grid item xs={6}>
                        {loadingTitle?
                            <CircularProgress />:
                            <>
                                <Typography variant="h5" fontWeight="bold" sx={styles.tableTitle}>
                                    {
                                        showTotal? `${title} ₱ ${total}`: title
                                    }
                                    <Button variant="contained" onClick={apply} disabled={!!loading} sx={{margin: '0 1rem'}}>
                                        {loading? <CircularProgress size="20px" />: 'REFRESH'}
                                    </Button>
                                </Typography>
                                {
                                    showBalance &&
                                    <Typography variant="h6" sx={styles.tableSubtitle}>
                                        Balance: ₱ {balance}
                                    </Typography>
                                }
                            </>
                        }
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container
                            spacing        = {2}
                            alignItems     = "center"
                            justifyContent = "flex-end"
                        >
                            <Grid item>
                                <TextField variant="outlined"
                                    value           = {search}
                                    label           = "Search"
                                    InputLabelProps = {{ shrink: true }}
                                    onChange        = {(e: any) => setSearch(e.target.value.toLowerCase())}
                                    style           = {styles.searchField}
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    id              = "date"
                                    label           = "Start Date"
                                    type            = "date"
                                    value           = {start}
                                    inputProps      = {{max: end}}
                                    InputLabelProps = {{shrink: true}}
                                    onChange        = {(e: any) => setStart(e.target.value)}
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    id              = "date"
                                    label           = "End Date"
                                    type            = "date"
                                    value           = {end}
                                    InputLabelProps = {{shrink: true}}
                                    onChange        = {(e: any) => setEnd(e.target.value)}
                                />
                            </Grid>
                            <Grid item>
                                <Button
                                    variant = "contained"
                                    color   = "primary"
                                    size    = "large"
                                    onClick = {apply}
                                    disabled = {!!loading}
                                >
                                    {loading? <CircularProgress size="20px" />: 'APPLY'}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <TableContainer component={Paper} sx={styles.tableContainer}>
                    <MuiTable size="small" stickyHeader style={{minWidth: 'jpt' == bank? '2000px': '100%'}}>
                        <TableHead>
                            {
                                "allbank" == bank? (
                                    props?.type == 'partner' && (
                                        ['AllAccess', 'QPay'].includes(props?.partner?.profile?.client) ||
                                        ['Jovy'].includes(props?.partner?.profile?.account)
                                    ) &&
                                    <QPayColumns/>
                                ) || <AllBankColumns isAdmin={isAdmin}/>:
                                "rbgi"         == bank? <RBGIColumns/>:
                                "icash"        == bank? <ICashColumns/>:
                                "gcash"        == bank? <GCashColumns isAdmin={isAdmin}/>:
                                "jpt"          == bank? <JPTColumns isAdmin={isAdmin}/>:
                                "credit-debit" == bank? <CreditDebitColumns isAdmin={isAdmin}/>:
                                <></>
                            }
                        </TableHead>
                        <TableBody>
                            {(loading? []: transactions).map(item =>(
                                "allbank" == bank? 
                                (
                                    props?.type == 'partner' && (
                                        ['AllAccess', 'QPay'].includes(props?.partner?.profile?.client) ||
                                        ['Jovy', 'Tams'].includes(props?.partner?.profile?.account)
                                    ) &&
                                    <QPayRow
                                        item = {item}
                                        isAdmin = {isAdmin}
                                        amountFormat = {amountFormat}
                                        computeAmount = {computeAmount}
                                    />
                                ) || <AllBankRow item={item} isAdmin={isAdmin} amountFormat={amountFormat} computeAmount={computeAmount}/>:
                                "rbgi"         == bank? <RBGIRow item={item}/>:
                                "icash"        == bank? <ICashRow item={item}/>:
                                "gcash"        == bank? <GCashRow item={item} isAdmin={isAdmin} amountFormat={amountFormat} />:
                                "jpt"          == bank? <JPTRow item={item} isAdmin={isAdmin} amountFormat={amountFormat} />:
                                "credit-debit" == bank? <CreditDebitRow item={item} isAdmin={isAdmin} amountFormat={amountFormat} />:
                                <></>
                            ))}
                        </TableBody>
                    </MuiTable>
                </TableContainer>
                <Grid container alignContent="space-between">
                    <Grid item>
                        <Button
                            color   = "primary"
                            variant = "contained"
                            disabled = {!!downloading}
                            style   = {styles.downloadButton}
                            onClick = {() => download('xlsx')}
                        >
                            Download (.xlsx)
                        </Button>
                        <Button
                            color   = "primary"
                            variant = "contained"
                            disabled = {!!downloading}
                            style   = {styles.downloadButton}
                            onClick = {() => download('csv')}
                        >
                            Download (.csv)
                        </Button>
                    </Grid>
                    <Grid item>
                        <TablePagination
                            component          = "div"
                            align              = "right"
                            count              = {count}
                            onPageChange       = {onChangePage}
                            page               = {page}
                            rowsPerPage        = {rowsPerPage}
                            rowsPerPageOptions = {[]}
                            slotProps          = {{actions: {nextButton: { disabled: false }}}}
                        />
                    </Grid>
                </Grid>
            </Card>
        </Grid>
    </>
})

const styles = {
    tableCard: {
        minHeight: '100px',
        padding: '1rem'
    },
    tableContainer: {
        margin         : '1rem 0',
        height         : '100%',
        maxHeight      : 440,
        backgroundColor: "#fff"
    },
    tableTitle: {
        margin: 0
    },
    tableSubtitle: {
        margin    : 0,
        fontWeight: 'normal'
    },
    searchField: {
        marginRight: '1rem',
        marginLeft: '1rem'
    },
    downloadButton: {
        margin: '0.5rem'
    }
}

export default TransactionsTable