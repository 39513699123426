import { Grid } from "@mui/material"
import AccountsTable from "app/components/AccountsTable"
import AdminTerminalTable from "app/components/AdminTerminalTable"
import BankTransactionsTable from "app/components/TransactionTables/BankTransactionsTable"
import { useParams } from "react-router-dom"

const AdminDashboard = ({partner, type, api, goToAccount}: any) => {
    const {type: user_type} = useParams();

    return <Grid container spacing={3}>
        <Grid item xs={12}>
            <AccountsTable partner={partner} goToAccount={goToAccount} />
        </Grid>
        {partner?.profile?.banks?.allbank && <>
            {partner?.profile?.p2m?.enabled &&
                <BankTransactionsTable showBalance
                    bank    = "allbank"
                    source  = "p2m"
                    title   = {user_type?.toLowerCase() == 'admin'? "AllBank - P2M": "QRPH"}
                    partner = {partner}
                    type    = {type || ''}
                    api     = {api}
                />
            }
            {partner?.profile?.opc?.enabled &&
                <BankTransactionsTable showBalance
                    bank    = "allbank"
                    source  = "opc"
                    title   = "AllBank - OPC"
                    partner = {partner}
                    type    = {type || ''}
                    api     = {api}
                />
            }
        </>}
        {partner?.profile?.banks?.jpt && <>
            {partner?.profile?.jpt?.cash_in &&
                <BankTransactionsTable showTotal
                    bank    = "jpt"
                    intent  = "cash_in"
                    title   = "Cash In"
                    partner = {partner}
                    type    = {type || ''}
                    api     = {api}
                />
            }
            {partner?.profile?.jpt?.cash_out &&
                <BankTransactionsTable showTotal
                    bank    = "jpt"
                    intent  = "cash_out"
                    title   = "Cash Out"
                    partner = {partner}
                    type    = {type || ''}
                    api     = {api}
                />
            }
        </>}
        {partner?.profile?.banks?.credit_debit &&
            <BankTransactionsTable showTotal
                bank    = "credit-debit"
                title   = "Credit/Debit Cards"
                partner = {partner}
                type    = {type || ''}
                api     = {api}
            />
        }
        {partner?.profile?.banks?.rbgi &&
            <Grid item xs={12}>
                <BankTransactionsTable showBalance
                    bank    = "rbgi"
                    title   = "RBGI"
                    partner = {partner}
                    type    = {type || ''}
                    api     = {api}
                />
            </Grid>
        }
        {partner?.profile?.banks?.icash &&
            <Grid item xs={12}>
                <BankTransactionsTable showBalance
                    bank    = "icash"
                    title   = "ICash"
                    partner = {partner}
                    type    = {type || ''}
                    api     = {api}
                />
            </Grid>
        }
        {partner?.profile?.banks?.gcash &&
            <Grid item xs={12}>
                <BankTransactionsTable showTotal
                    bank    = "gcash"
                    title   = "GCash"
                    partner = {partner}
                    type    = {type || ''}
                    api     = {api}
                />
            </Grid>
        }
        {partner?.profile?.banks?.allbank &&
            <Grid item xs={12} lg={6}>
                <AdminTerminalTable bank_title="AllBank" partner={partner}/>
            </Grid>
        }
        {partner?.profile?.banks?.rbgi &&
            <Grid item xs={12} lg={6}>
                <AdminTerminalTable bank_title="RBGI" partner={partner}/>
            </Grid>
        }
        {partner?.profile?.banks?.icash &&
            <Grid item xs={12} lg={6}>
                <AdminTerminalTable bank_title="ICash" partner={partner}/>
            </Grid>
        }
    </Grid>
}

export default AdminDashboard;