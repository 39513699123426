import { NavigateNext } from "@mui/icons-material";
import { Breadcrumbs, Button, Grid, Link, Typography } from "@mui/material";

interface Props {
    stack?: string[]
    setStack?: (value: string[]) => any
    children?: any
}

const NavigationHeader = ({stack, setStack, children}: Props) => {

    const logout = () => {
        sessionStorage.clear();
        window.location.reload();
    }

    return <>
        <Grid container style={{padding: '1rem'}}>
            <Grid item xs={12} container>
                <Grid item>
                    <Breadcrumbs separator={<NavigateNext fontSize="small"/>}>
                        {stack?.map((header, i) =>
                            i === stack?.length-1?
                            <Typography color="inherit" >
                                {header}
                            </Typography>:
                            <Link
                                underline = "hover"
                                color     = "primary"
                                sx        = {styles.breadCrumbLink}
                                onClick   = {() => setStack && setStack(stack.slice(0, i+1))}
                            >
                                {header}
                            </Link>
                        )}
                    </Breadcrumbs>
                </Grid>
                <Grid item sx={{textAlign: 'right', marginLeft: 'auto'}}>
                    <Button variant="text" onClick={logout}>
                        Log out
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    {children}
                </Grid>
            </Grid>
        </Grid>
    </>
}

const styles = {
    breadCrumbLink: {
        cursor: 'pointer'
    }
}

export default NavigationHeader;