import { useEffect, useState } from "react";

const useAuth = () => {
    const [user, setUser] = useState<any>({});

    const getUser = () => {
        let user = sessionStorage.getItem('user')
        if (user) {
            user = JSON.parse(user)
            setUser(user)
        }
    }

    useEffect(getUser, [])

    return {
        id: user?.profile?.url,
        access_token: user?.access_token
    }
}

export default useAuth;