import { ACCOUNTS_USER, ACCOUNT_DOWNLOAD_TRANSACTIONS, ACCOUNT_TERMINAL, ACCOUNT_TERMINALS, ACCOUNT_TOTAL, ACCOUNT_TRANSACTIONS } from "app/urls";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

interface Props {
    account: string
    partner: any
}

const AccountsController = ({partner, account: username}: Props) => {
    const {id, type} = useParams();
    const {access_token} = partner;
    const [client, account] = username.split('-');
    const [user, setUser] = useState<any>();

    const fetchAPI = (params: any, access_token: string, base_url: any) => {
        const url = new URL(base_url(params));
        url.searchParams.append('client', client)
        url.searchParams.append('account', account)
        return fetch(url.toString(), {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${access_token}`
            },
        })
    }
    const fetchTotal           = (params: any, access_token: string) => fetchAPI(params, access_token, ACCOUNT_TOTAL);
    const fetchTransactions    = (params: any, access_token: string) => fetchAPI(params, access_token, ACCOUNT_TRANSACTIONS);
    const fetchTerminals       = (params: any, access_token: string) => fetchAPI(params, access_token, ACCOUNT_TERMINALS);
    const downloadTransactions = (params: any, access_token: string) => fetchAPI(params, access_token, ACCOUNT_DOWNLOAD_TRANSACTIONS);

    const fetchUser = () => {
        fetch(ACCOUNTS_USER({id, username}), {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${access_token}`
            },
        })
        .then(res => res.json())
        .then(res => {
            if (res) {
                for (const attr of Object.keys(res.attributes)) {
                    let value = res.attributes[attr];
                    try {
                        value = JSON.parse(value);
                    } catch {
                    } finally {
                        res.attributes[attr] = value;
                    }
                }
                setUser(res)
            }
        });
    }

    const removeTerminalId = (params: any, access_token: string) => {
        return fetch(
            ACCOUNT_TERMINAL(params), {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${access_token}`
            },
        })
    }

    useEffect(fetchUser, [username]);

    return {
        isAdmin: type?.toLowerCase() == 'admin',
        user,
        fetchTotal,
        fetchTransactions,
        fetchTerminals,
        removeTerminalId,
        downloadTransactions
    }
}

export default AccountsController;