import { useEffect, useState } from "react";

interface DashboardControllerProps {
    partner?: any
}

const PageController = ({partner}: DashboardControllerProps) => {
    // -- States --

    const [page, setPage] = useState('login');

    // -- End Of States --
    // -- Functions --

    const redirectToDashboard = () => partner && setPage('settlements');
    const redirectToResetPassword = () => setPage('resetPassword');


    // -- End Of Functions --
    // -- useEffects --

    useEffect(redirectToDashboard, [partner]);

    // -- End Of useEffects --

    return {
        page, setPage,
        redirectToDashboard,
        redirectToResetPassword,
    }
}

export default PageController;