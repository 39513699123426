import { TableCell, TableRow } from "@mui/material";

const JPTColumns = ({isAdmin}: any) => {
    return isAdmin?
        <TableRow>
            <TableCell>client</TableCell>
            <TableCell>payment_id</TableCell>
            <TableCell>reference_number</TableCell>
            <TableCell>tranID</TableCell>
            <TableCell>tranDate</TableCell>
            <TableCell>intent</TableCell>
            <TableCell>email</TableCell>
            <TableCell>account_name</TableCell>
            <TableCell>account_number</TableCell>
            <TableCell>bank_wallet</TableCell>
            <TableCell>currency</TableCell>
            <TableCell>tranAmount</TableCell>
            <TableCell>amount</TableCell>
            <TableCell>status</TableCell>
        </TableRow>:
        <TableRow>
            <TableCell>Reference No.</TableCell>
            <TableCell>Transaction ID</TableCell>
            <TableCell>Client Reference</TableCell>
            <TableCell>Transaction Date</TableCell>
            <TableCell>Intent</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Account Name</TableCell>
            <TableCell>Account Number</TableCell>
            <TableCell>Bank/Wallet</TableCell>
            <TableCell>Currency</TableCell>
            <TableCell>Transaction Amount</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Status</TableCell>
        </TableRow>
}

export default JPTColumns;