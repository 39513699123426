import { TableCell, TableRow } from "@mui/material";

const CreditDebitColumns = ({isAdmin}: any) => {
    return isAdmin?
        <TableRow>
            <TableCell>payment_id</TableCell>
            <TableCell>created_at</TableCell>
            <TableCell>updated_at</TableCell>
            <TableCell>intent</TableCell>
            <TableCell>username</TableCell>
            <TableCell>name</TableCell>
            <TableCell>currency</TableCell>
            <TableCell>gross</TableCell>
            <TableCell>net</TableCell>
            <TableCell>status</TableCell>
        </TableRow>:
        <TableRow>
            <TableCell>Transaction ID</TableCell>
            <TableCell>Created At</TableCell>
            <TableCell>Updated At</TableCell>
            <TableCell>Intent</TableCell>
            <TableCell>Username</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Currency</TableCell>
            <TableCell>Gross</TableCell>
            <TableCell>Net</TableCell>
            <TableCell>Status</TableCell>
        </TableRow>
}

export default CreditDebitColumns;