import { TableCell, TableRow } from "@mui/material";

const RBGIRow = ({item}: any) => {
    return (
        <TableRow hover>
        <TableCell>{item.reference_id || '-'}</TableCell>
        <TableCell>{item.amount || '-'}</TableCell>
        <TableCell>{item.postingTime || '-'}</TableCell>
        <TableCell>{item.InstrId || '-'}</TableCell>
        <TableCell>{item.MsgId || '-'}</TableCell>
        <TableCell>{item.OrgnlTxId || '-'}</TableCell>
        <TableCell>{item.OrgnlEndToEndId || '-'}</TableCell>
        <TableCell>{item.CreDtTm || '-'}</TableCell>
        <TableCell>{item.CreDtm || '-'}</TableCell>
        <TableCell>{item.TtlIntrBkSttlmAmt || '-'}</TableCell>
        <TableCell>{item.TxSts || '-'}</TableCell>
        <TableCell>{item.AccptncDtTm || '-'}</TableCell>
        <TableCell>{item.Rsn || '-'}</TableCell>
        <TableCell>{item.acctno || '-'}</TableCell>
        <TableCell>{item.institution || '-'}</TableCell>
        <TableCell>{item.bic || '-'}</TableCell>
        <TableCell>{item.transactionFee || '-'}</TableCell>
        <TableCell>{item.status || '-'}</TableCell>
        </TableRow>
    )
}

export default RBGIRow;