import { Navigate, Route, Routes as Switch } from "react-router-dom";

import Dashboard from "./pages/Dashboard";
import Home from "./pages/Home";
import ResetPassword from "./pages/ResetPassword";
import Settlements from "./pages/Settlements";

const Routes = () => {
    return <>
        <Switch>
            <Route path="/" element={<Home/>}/>
            <Route path="/:type/settlements" element={<Settlements/>}/>
            <Route path="/:type/:id" element={<Dashboard/>}/>
            <Route path="/:type/:id/reset-password" element={<ResetPassword/>}/>
            <Route element={<Navigate to="/"/>}/>
        </Switch>
    </>
}

export default Routes;