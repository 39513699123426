import { Box, Card, CardContent, CircularProgress, FormControlLabel, FormGroup, FormHelperText, Grid, Button as MuiButton, TextField, Typography } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { NumberFormatBase, NumberFormatBaseProps, useNumericFormat } from "react-number-format";
import Swal from "sweetalert2";
import useApi from "./useApi";

interface AccountProp {
    date: string
    account: any
}

const Account = ({date, account: initAccount}: AccountProp) => {

    const {changeSettlement} = useApi();

    const [account, setAccount]   = useState(initAccount);
    const [total, setTotal]       = useState(account.amount || account.net || 0);
    const [changing, setChanging] = useState(false);
    const [settled, setSettled]   = useState(account.status && ['processing', 'completed'].includes(account.status))

    const change = async () => {
        if (!total) {
            Swal.fire({
                title: 'Invalid Total',
                icon: 'error'
            })
            return
        }
        setChanging(true)
        await changeSettlement({
            date, total,
            source : account.source,
            account_name : account.name
        }).then(async res => {
            if (200 == res.status) {
                Swal.fire({
                    title: 'Success',
                    text: `Changed total to ${total.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'PHP',
                    })}`,
                    icon: 'success'
                })
                let settlement = await res.json();
                updateAccount({
                    amount      : settlement.amount,
                    last_updated: settlement.last_updated,
                    approvers   : settlement.approvers
                })
            } else {
                Swal.fire({
                    title: `Failed (${res.status})`,
                    text: await res.text(),
                    icon: 'error'
                })
            }
            return
        })
        setChanging(false)
    }

    const updateAccount = (settlement: any) => setAccount({...account, ...settlement})

    useEffect(() => setTotal(account.amount || account.net || 0), [account])
    useEffect(() => setSettled(account.status && ['processing', 'completed'].includes(account.status)), [account])

    return (
        <Grid item xs={12} md={6} lg={4}>
            <Card>
                <CardContent>
                    <Box>
                        <Typography variant="h5">{account.name}</Typography>
                        <Typography gutterBottom variant="subtitle2" fontWeight="normal">
                            <InlineLabeledText label="Settlement Date" value={moment(account.settlement_date).format('MMMM DD, YYYY')} />
                            <InlineLabeledText label="Gross" value={
                                account.gross.toLocaleString('en-US', {
                                    style: 'currency',
                                    currency: 'PHP',
                                })
                            } />
                            <InlineLabeledText label="Fee" value={{
                                percentage: `${account.fees.value}%`,
                                fixed: (+account.fees.value).toLocaleString('en-US', {
                                    style: 'currency',
                                    currency: 'PHP',
                                })
                            }[account.fees.type as string]} />
                            <InlineLabeledText label="Net" value={
                                account.net.toLocaleString('en-US', {
                                    style: 'currency',
                                    currency: 'PHP',
                                })
                            } />
                            <InlineLabeledText label="Source" value={{
                                allbank_p2m: 'AllBank P2M'
                            }[account.source as string] || account.source} />
                        </Typography>
                    </Box>
                    <NumberInput
                        value    = {total}
                        label    = "Total: ₱"
                        disabled = {settled}
                        helper   = {account.last_transaction?.tranDate && `as of ${formatDate(account.last_transaction.tranDate)}`}
                        onChange = {(e: any) => setTotal(+(e.target.value.replaceAll(',', '')))}
                    />
                    <LoaderButton onClick={change} loading={changing} disabled={settled || changing} display="inline-block">Change</LoaderButton>
                    {account.last_updated &&
                        <Typography variant="caption" display="inline" style={{margin: '0 1rem'}}>last changed {formatDate(account.last_updated)}</Typography>
                    }
                </CardContent>
                <CardContent>
                    <InlineLabeledText label="Bank" value={account.bank.name}/>
                    <InlineLabeledText label="Account Name" value={account.bank.account_name}/>
                    <InlineLabeledText label="Account Number" value={account.bank.account_number}/>

                    <ApproverButton
                        date            = {date}
                        account         = {account}
                        approver_number = {1}
                        updateAccount   = {updateAccount}
                    />
                    <ApproverButton
                        date            = {date}
                        account         = {account}
                        approver_number = {2}
                        updateAccount   = {updateAccount}
                    />

                    {account.status && <InlineLabeledText
                        label      = "Status"
                        value      = {account.status.toUpperCase()}
                        valueProps = {{color: statusColors[account.status]}}
                    />}
                    {'failed' == account.status && <>
                        <Typography fontWeight="bold">Last Failed Attempt:</Typography>
                        <Typography>{JSON.stringify(account.last_failed_attempt)}</Typography>
                    </>}
                </CardContent>
            </Card>
        </Grid>
    )
}

const ApproverButton = ({date, account, approver_number, updateAccount}: any) => {
    const {approveSettlement} = useApi();

    const {
        source, completed, approvers,
        name: account_name,
    } = account;
    const key = `approver${approver_number}`;

    const [approving, setApproving] = useState(false);

    const approve = async () => {
        setApproving(true)
        await approveSettlement({date, source, approver_number, account_name}).then(async res => {
            if (200 == res.status) {
                Swal.fire({
                    title: 'Success',
                    text: `Approved ${account_name}`,
                    icon: 'success'
                })
                let settlement = await res.json();
                updateAccount({
                    ...settlement,
                    bank    : settlement.bank || account.bank,
                    provider: settlement.bank || account.bank,
                })
            } else {
                let response: any = await res.text();
                try {
                    response = JSON.parse(response)
                    response = response.detail.response.result_message
                } catch {}
                Swal.fire({
                    title: `Failed (${res.status})`,
                    text: response,
                    icon: 'error'
                })
            }
            return
        })
        setApproving(false)
    }

    return <Box>
        <LoaderButton
            disabled = {completed || !!approvers?.[key] || approving}
            loading  = {approving}
            onClick  = {approve}
            display  = "inline-block"
        >
            Approver {approver_number}
        </LoaderButton>
        <Typography variant="caption" display="inline" style={{margin: '0 0.5rem'}}>{
            approvers?.[key]?.approved_at &&
            <>approved {formatDate(approvers?.[key]?.approved_at)}</>
        }</Typography>
    </Box>
}

const formatDate = (date: string) => moment(date).format('MMMM DD, YYYY HH:mm:ss')

const Input = (props: any) => <TextField variant="outlined" style={{margin: '0 0.5rem'}} {...props}/>

const LoaderButton = (props: any) => (
    <MuiButton
        variant  = "contained"
        sx       = {{display: props.display || 'block', margin: '1rem 0'}}
        disabled = {props.disabled}
        {...props}
    >
        {
        props.loading?
        <CircularProgress size={20}/>:
        props.children
    }
    </MuiButton>
)

const InlineLabeledText = ({label, value, labelProps, valueProps}: any) => <Box>
    <Typography display="inline" fontWeight="bold" {...labelProps}>{label}:</Typography>{' '}
    <Typography display="inline" {...valueProps}>{value}</Typography>
</Box>

const NumberInput = ({value, onChange, label, helper, disabled}: any) => {
    const numericFormat: NumberFormatBaseProps = useNumericFormat({
        thousandSeparator: ',',
        decimalSeparator : '.',
    })
    return (
        <FormGroup>
            <FormControlLabel
                control = {
                    <NumberFormatBase
                        {...numericFormat}
                        value       = {value}
                        disabled    = {disabled}
                        customInput = {Input}
                        onChange={onChange}
                    />
                }
                label           = {label}
                labelPlacement  = "start"
                componentsProps = {{typography: {fontWeight: "bold"}}}
                style           = {{alignSelf: 'start', margin: 0}}
                
            />
            {helper && <FormHelperText color="GrayText">{helper}</FormHelperText>}
        </FormGroup>
    )
}

const statusColors: any = {
    processing: 'warning.main',
    completed: 'success.main',
    failed: 'error.main'
}

export default Account;