import { LoginForm } from "app/components/Login";
import { CHECK_ID, LOGIN } from "app/urls";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const LoginController = () => {
    // -- States --

    const {id, type}                 = useParams();
    const [valid_id, setValidId]     = useState(true);
    const [valid_type, setValidType] = useState(true);
    const [partner, setPartner]      = useState<any>();
    const [interval, setIntervalId]  = useState<number>();

    // -- End Of States --
    // -- Functions --

    const checkId = () => {
        fetch(CHECK_ID({id, type}), {method: 'POST'}).then(
            res => setValidId(res.status === 200)
        ).catch(
            () => setValidId(false)
        );
    }

    const checkType = () => setValidType(['admin', 'partner'].includes(type || ''));

    const login = async (form: LoginForm) => {
        let response = await fetch(LOGIN({id, type}), {
            method : "POST",
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(form)
        });
        let body = await response.text().catch(() => "");
        try {
            let partner = JSON.parse(body);
            if (typeof partner === 'object') {
                setPartner(partner);
                sessionStorage.setItem('user', JSON.stringify(partner));
            } else {
                return partner;
            }
        } catch {
            return body;
        }
    }

    const invalidUrl = () => {
        if (!valid_type || !valid_id) {
            window.location.href = "/"
        }
    }

    const loadUser = () => {
        let user = sessionStorage.getItem('user');
        if (user) {
            user = JSON.parse(user);
            if (
                !(user as any)?.profile?.groups?.includes(type?.toLowerCase() || '') ||
                (user as any)?.profile?.url != id
            ) {
                sessionStorage.clear();
                window.location.reload();
            } else {
                setPartner(user);
            }
        }
    }

    // -- End Of Functions --
    // -- useEffects --

    useEffect(loadUser, []);
    useEffect(checkId, [id]);
    useEffect(checkType, [type]);
    useEffect(invalidUrl, [valid_type, valid_id])

    // -- End Of useEffects --

    return {
        partner,
        login
    }
}

export default LoginController;