import { TableCell, TableRow } from "@mui/material";

const AllBankColumns = ({isAdmin}: any) => {
    return isAdmin?
        <TableRow>
            <TableCell>client</TableCell>
            <TableCell>id</TableCell>
            <TableCell>transaction_id</TableCell>
            <TableCell>reference_number</TableCell>
            <TableCell>created_at</TableCell>
            <TableCell>paid_at</TableCell>
            <TableCell>amount</TableCell>
            <TableCell>status</TableCell>
            <TableCell>refno</TableCell>
        </TableRow>:
        <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Transaction ID</TableCell>
            <TableCell>Reference Number</TableCell>
            <TableCell>Created At</TableCell>
            <TableCell>Paid At</TableCell>
            <TableCell>Transaction Amount</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Ref No.</TableCell>
        </TableRow>
}

export default AllBankColumns;