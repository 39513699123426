import { BALANCE, DOWNLOAD_TRANSACTIONS, TERMINAL, TERMINALS, TOTAL, TRANSACTIONS } from "app/urls";

const DashboardController = () => {
    const fetchAPI = (params: any, access_token: string, url: any) => fetch(url(params), {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${access_token}`
        },
    })
    const fetchBalance         = (params: any, access_token: string) => fetchAPI(params, access_token, BALANCE);
    const fetchTotal           = (params: any, access_token: string) => fetchAPI(params, access_token, TOTAL);
    const fetchTransactions    = (params: any, access_token: string) => fetchAPI(params, access_token, TRANSACTIONS);
    const fetchTerminals       = (params: any, access_token: string) => fetchAPI(params, access_token, TERMINALS);
    const downloadTransactions = (params: any, access_token: string) => fetchAPI(params, access_token, DOWNLOAD_TRANSACTIONS);

    const removeTerminalId = (params: any, access_token: string) => {
        return fetch(
            TERMINAL(params), {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${access_token}`
            },
        })
    }

    return {
        fetchBalance,
        fetchTotal,
        fetchTransactions,
        fetchTerminals,
        removeTerminalId,
        downloadTransactions
    }
}

export default DashboardController;