import { Button, Card, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { ACCOUNTS_TRANSACTION_SPLIT } from "app/urls";
import { useState } from "react";
import { useParams } from "react-router-dom";

const Controller = ({username, access_token}: any) => {
    const {id} = useParams();
    const save = (bank: string, value: number) => {
        fetch(
            ACCOUNTS_TRANSACTION_SPLIT({id, username, bank}), {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${access_token}`
                },
                body: JSON.stringify({split: value})
            }
        )
    }

    return {
        save
    }
}

const TransactionSplitTable = ({partner, user}: any) => {
    const {save} = Controller({
        username    : user?.username,
        access_token: partner.access_token
    });

    return <>
        <Grid item xs={true}>
            <Card style={styles.tableCard}>
                <Grid container alignItems="center" alignContent="space-between" direction="row">
                    <Grid item xs={6}>
                        <Typography variant="h5" fontWeight="bold" sx={styles.tableTitle}>Total: </Typography>
                    </Grid>
                </Grid>
                <TableContainer component={Paper} sx={styles.tableContainer}>
                    <Table size="small" stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>Transaction Split</TableCell>
                                <TableCell>Account</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.entries(user?.attributes?.sof_distribution?.qrph || {}).map(([key, value]) => (
                                <TableRow>
                                    <TableCell>{key}</TableCell>
                                    <TableCell>
                                        <Form save={save} bank={key} value={value}/>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Card>
        </Grid>
    </>
}

const Form = ({save, bank, value}: any) => {
    const [split, setSplit] = useState(value);
    return <>
        <TextField
            variant    = "outlined"
            size       = "small"
            type       = "number"
            inputProps = {{pattern:"[0-9]", min: 0}}
            sx         = {{width: '75px'}}
            value      = {split || 0}
            onChange   = {(e) => setSplit(+e.target.value || 0)}
        />
        <Button
            variant = "contained"
            sx      = {{
                margin       : 'auto 0.5rem',
                borderRadius : '100px',
                textTransform: 'none'
            }}
            onClick = {() => save(bank, split)}
        >
            save
        </Button>
    </>
}

const styles = {
    tableCard: {
        minHeight: '100px',
        padding: '1rem'
    },
    tableContainer: {
        maxWidth: '500px',
        margin         : '1rem 0',
        height         : '100%',
        maxHeight      : 440,
        backgroundColor: "#fff"
    },
    tableTitle: {
        margin: 0
    },
    tableSubtitle: {
        margin    : 0,
        fontWeight: 'normal'
    },
    searchField: {
        marginRight: '1rem',
        marginLeft: '1rem'
    }
}

export default TransactionSplitTable;